import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbf9UN6fGIaM9o0iEKqmZTd8rn4J7IV8Q",
  authDomain: "sizzle-landing-3d452.firebaseapp.com",
  projectId: "sizzle-landing-3d452",
  storageBucket: "sizzle-landing-3d452.appspot.com",
  messagingSenderId: "367406633546",
  appId: "1:367406633546:web:b8c1300727f637ccbe4cb3",
  measurementId: "G-XJH98PJQQL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
import { collection, addDoc, getDoc, doc, updateDoc } from 'firebase/firestore';
   import db from './firestore';

   export interface User {
     id?: string;
     email?: string;
     walletAddress?: string;
     additionalInfo?: string;
   }

   export const userCollection = collection(db, 'users');

   export const addUser = async (user: User): Promise<string> => {
     const docRef = await addDoc(userCollection, user);
     return docRef.id;
   };

   export const getUserById = async (id: string): Promise<User | null> => {
     const docRef = doc(db, 'users', id);
     const docSnap = await getDoc(docRef);
     if (!docSnap.exists()) {
       return null;
     }
     return { id: docSnap.id, ...docSnap.data() } as User;
   };

   export const updateUser = async (id: string, data: Partial<User>): Promise<void> => {
     const docRef = doc(db, 'users', id);
     await updateDoc(docRef, data);
   };
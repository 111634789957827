import React, { useState } from 'react';
   import { addUser, User } from './userService';

   const App: React.FC = () => {
     const [email, setEmail] = useState('');
     const [walletAddress, setWalletAddress] = useState('');
     const [message, setMessage] = useState('');

     const handleSubmit = async (e: React.FormEvent) => {
       e.preventDefault();
       try {
         const user: User = {};
         if (email) user.email = email;
         if (walletAddress) user.walletAddress = walletAddress;
         
         const userId = await addUser(user);
         setMessage(`User added successfully with ID: ${userId}`);
         setEmail('');
         setWalletAddress('');
       } catch (error) {
         setMessage('Error adding user. Please try again.');
         console.error('Error adding user:', error);
       }
     };

     return (
       <div className="App">
         <header className="bg-blue-500 text-white p-4">
           <h1 className="text-2xl font-bold">Welcome to Our Software Company</h1>
         </header>
         <main className="p-4">
           <p className="mb-4">We're excited to share our innovative solutions with you soon!</p>
           <form onSubmit={handleSubmit} className="mb-4">
             <input
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               placeholder="Enter your email"
               className="border p-2 mr-2"
             />
             <input
               type="text"
               value={walletAddress}
               onChange={(e) => setWalletAddress(e.target.value)}
               placeholder="Enter your wallet address"
               className="border p-2 mr-2"
             />
             <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
               Submit
             </button>
           </form>
           {message && <p className="text-green-500">{message}</p>}
         </main>
       </div>
     );
   };

   export default App;